.button {
    font-family: 'KasperskySans';
    outline: none;
    background: none;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    cursor: pointer;    
    height: 48px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    min-width: 160px;
    padding: 0 40px;
    border-radius: 3px;
  }
  .button [class^=icon_] {
    margin-right: 30px;
  }
  .button_gray {
    background: #1D1D1B;
    box-shadow: 0px 8px 32px rgba(0, 95, 80, 0.52);
    color: #fff;
    height: 70px;
  }
  .button_custom {
    color: #fff;
    border: 2px solid #FFFFFF;
    font-size: 24px;
    height: 63px;
    padding: 0 46px;
  }
  .button_custom [class^=icon_] {
    fill: #fff;
  }
  .button_clear {
    border: 2px solid #FFFFFF;
    color: #444444;
  }
  .button_clear._small {
    border: none;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
  }
  .button_green {
    background: #00A88E;
    color: #fff;
  }