.profile {
  padding-top: 55px;
}
.profile .container {
  padding: 0 143px;
}
.profile__title {
  font-size: 36px;
  line-height: 52px;
  letter-spacing: -0.03em;
  color: #1D1D1B;
  font-weight: bold;
}
.profile__header {
  margin: 126px 0 20px;
  background: #F6FBFD;
}
.profile__header .container {
  position: relative;
  padding: 21px 187px 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
}
.profile__photo {
  min-width: 170px;
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
}
.profile__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.profile__common {
  padding-left: 36px;
  padding-bottom: 9px;
}
.profile__name {
  font-size: 21px;
  line-height: 24px;
  color: #00A88E;
  font-weight: bold;
  margin-bottom: 13px;
}
.profile__info {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.08em;
  color: #1D1D1B;
  margin-bottom: 13px;
}
.profile__slogan {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
}
.profile__prev {
  position: absolute;
  left: 240px;
  top: -80px;
}
.profile__prev a {
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
}
.profile__prev a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  filter: grayscale(100);
}
.profile__next {
  position: absolute;
  left: 240px;
  bottom: -80px;
}
.profile__next a {
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
}
.profile__next a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  filter: grayscale(100);
}
.profile__content {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  padding-bottom: 69px;
}
.profile__content p {
  margin-bottom: 8px;
}
.profile__content > *:last-child {
  margin-bottom: 0;
}
.profile__content .container {
  padding-left: 393px;
}
.profile__comments {
  background: #F6FBFD;
  padding: 26px 0 95px;
}

.comments__title {
  font-weight: 800;
  font-size: 28px;
  line-height: 32px;
  color: #1D1D1B;
  margin-bottom: 35px;
}
.comments__count {
  color: #00A88E;
}
.comments__list .item._comment {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 33px;
  display: -ms-flexbox;
  display: flex;
  padding: 23px 30px 25px;
  min-height: 168px;
  -ms-flex-align: start;
  align-items: flex-start;
}
.comments__list .item._comment .item__common {
  width: 100%;
  padding-right: 35px;
  padding-top: 3px;
}
.comments__list .item._comment .item__date {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  margin-bottom: 30px;
}
.comments__list .item._comment .item__time {
  color: #1D1D1B;
}
.comments__list .item._comment .item__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #1D1D1B;
  padding-left: 3px;
}
.comments__list .item._comment .item__author {
  width: 244px;
  min-width: 244px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.comments__list .item._comment .item__author-photo {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
}
.comments__list .item._comment .item__author-photo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comments__list .item._comment .item__author-common {
  padding-left: 10px;
}
.comments__list .item._comment .item__author-name {
  font-size: 14px;
  line-height: 18px;
  color: #1D1D1B;
  margin-bottom: 3px;
}
.comments__list .item._comment .item__author-info {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}

.form._comments {
  display: -ms-flexbox;
  display: flex;
}
.form._comments .form__field._sms {
  width: 100%;
  padding-right: 34px;
}
.form._comments .form__field._buttons {
  width: 274px;
  min-width: 274px;
}
.form._comments .form__field._buttons .button {
  width: 100%;
}
