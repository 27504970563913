.peoples {
    padding-top: 15px;
    background: url('img/awards_bg.png') center top;
    overflow: hidden;
  }
  .peoples__title {
    font-size: 36px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #1D1D1B;
    font-weight: bold;
    margin-bottom: 21px;
  }
  .peoples__wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .peoples__list {
    width: calc(50% - 16px);
  }
  .peoples__user {
    max-width: 600px;
    width: 50%;
    height: calc(100vh - 138px);
    position: fixed;
    left: 50%;
    padding-left: 16px;
  }
  .peoples__user-wrap {
    padding: 18px 11px 22px 30px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.24);
  }
  .peoples__user-wrap .user {
    height: 100%;
  }
  .peoples__user-wrap .user__content {
    max-height: calc(100% - 210px);
    overflow: auto;
    padding-top: 12px;
  }