.categories {
    background: url('img/awards_bg.png') center top;
    min-height: 100vh;
  }
  .categories .container {
    position: relative;
  }
  .categories._group {
    /* background-image: url('img/bg-5.png'); */
  }
  .categories__group-title {
    font-size: 36px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #1D1D1B;
    margin-bottom: 38px;
  }
  .categories__filter {
    position: relative;
    margin: 0 -16px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    z-index: 1000;
    padding-top: 77px;
  }
  .categories__filter .item {
    padding: 0 16px;
    margin-bottom: 30px;
  }
  .categories__types {
    position: relative;
    margin: 0 -16px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    z-index: 1000;
  }
  .categories__types .item._category {
    width: 33.3336%;
    padding: 0 16px;
    margin-bottom: 30px;
  }
  .categories__filter._sticky .item._date .item__wrap {
    width: 80px;
    height: 64px;
    position: relative;
  }
  .categories__filter._sticky .item._date .item__day {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .categories__filter._sticky .item._date .item__month, 
  .categories__filter._sticky .item._date .item__year {
    font-size: 10px;
    line-height: 12px;
  }
  .categories__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  .categories__list-dates {
    margin-top: -50px;
    overflow: hidden;
  }
  .categories__list .item._author {
    width: 25%;
    padding: 0 16px;
    margin-bottom: 30px;
  }
  .categories__menu {
    padding-top: 28px;
    padding-right: 127px;
    position: relative;
    z-index: 2000;
    background: transparent;
    transition: all .3s;
}
.categories__menu._sticky {
  background: #fff;
  padding-bottom: 28px;
}
.categories__menu ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style-type: none;
    align-items: flex-start;
}
.categories__menu ul a {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-decoration: none;
    color: #1D1D1B;
    opacity: .4;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    padding-bottom: 2px;
}
.categories__menu .react-datepicker-wrapper {
  width: 55px;
  white-space: nowrap;
}
.categories__menu ul .react-datepicker__input-container {
  padding-top: 0px;
}
.categories__menu ul .react-datepicker__input-container .active {
  font-weight: bold;
  opacity: 1;
}
.categories__menu ul .clear-filter-date {
  opacity: 1;
  display: inline-block;
  line-height: 16px;
  position: static;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-transform: none;
  margin-left: 5px;
  font-weight: bold;
  position: relative;
  top: -1px;
  color: red;
}
.categories__menu ul a .icon_chevron-bottom-small {
    margin-left: 10px;
    margin-top: -1px;
}
.categories__menu ul a:hover {
    opacity: 1;
}
.categories__menu ul li.active a {
    opacity: 1;
    border-bottom-color: #1D1D1B;
}

.react-datepicker.filter_datepicker {
  font-family: 'KasperskySans';
  border: none;
  border-radius: 0px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
}

.filter_datepicker .react-datepicker__day {
  color: #666;
}

.filter_datepicker .react-datepicker__day--disabled, 
.filter_datepicker .react-datepicker__month-text--disabled, 
.filter_datepicker .react-datepicker__quarter-text--disabled {
  opacity: .4;
}

.filter_datepicker .react-datepicker__day--keyboard-selected, 
.filter_datepicker .react-datepicker__month-text--keyboard-selected, 
.filter_datepicker .react-datepicker__quarter-text--keyboard-selected {
  border-radius: 50%;
  color: #23D1AE;
  background-color: transparent;
}

.filter_datepicker .react-datepicker__day--today, 
.filter_datepicker .react-datepicker__month-text--today, 
.filter_datepicker .react-datepicker__quarter-text--today {
  color: #23D1AE;
}

.filter_datepicker .react-datepicker__day--keyboard-selected:hover, 
.filter_datepicker .react-datepicker__month-text--keyboard-selected:hover, 
.filter_datepicker .react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: transparent;
}

.filter_datepicker .react-datepicker__day--selected, 
.filter_datepicker .react-datepicker__day--in-selecting-range, 
.filter_datepicker .react-datepicker__day--in-range, 
.filter_datepicker .react-datepicker__month-text--selected, 
.filter_datepicker .react-datepicker__month-text--in-selecting-range, 
.filter_datepicker .react-datepicker__month-text--in-range, 
.filter_datepicker .react-datepicker__quarter-text--selected, 
.filter_datepicker .react-datepicker__quarter-text--in-selecting-range, 
.filter_datepicker .react-datepicker__quarter-text--in-range {
  border-radius: 50%;
  background: #23D1AE;
  color: #fff;
}

.filter_datepicker .react-datepicker__day--selected:hover, 
.filter_datepicker .react-datepicker__day--in-selecting-range:hover, 
.filter_datepicker .react-datepicker__day--in-range:hover, 
.filter_datepicker .react-datepicker__month-text--selected:hover, 
.filter_datepicker .react-datepicker__month-text--in-selecting-range:hover, 
.filter_datepicker .react-datepicker__month-text--in-range:hover, 
.filter_datepicker .react-datepicker__quarter-text--selected:hover, 
.filter_datepicker .react-datepicker__quarter-text--in-selecting-range:hover, 
.filter_datepicker .react-datepicker__quarter-text--in-range:hover {
  background: #23D1AE;
}

.filter_datepicker .react-datepicker__day:hover, 
.filter_datepicker .react-datepicker__month-text:hover, 
.filter_datepicker .react-datepicker__quarter-text:hover {
  border-radius: 50%;
}

.filter_datepicker .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):hover, 
.filter_datepicker .react-datepicker__month-text:not(.react-datepicker__month-text--disabled):hover, 
.filter_datepicker .react-datepicker__quarter-text:not(.react-datepicker__quarter-text--disabled):hover {
  background-color: #f0f0f0;
}

.filter_datepicker .react-datepicker__navigation--previous:hover,
.filter_datepicker .react-datepicker__navigation--next:hover,
.filter_datepicker .react-datepicker__navigation {
  border-color: transparent;
  border: none;
  text-indent: 0;
}

.filter_datepicker .react-datepicker__navigation {
  top: 12px;
}

.filter_datepicker .react-datepicker__navigation--previous {
  height: 15px;
  color: transparent;
  outline: none;
}

.filter_datepicker .react-datepicker__navigation--next {
  height: 15px;
  color: transparent;
  outline: none;
}

.filter_datepicker .react-datepicker__navigation--previous:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  opacity: .6;
}

.filter_datepicker .react-datepicker__navigation--next:before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  width: 7px;
  height: 7px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  transform: rotate(45deg);
  opacity: .6;
}

.filter_datepicker .react-datepicker__navigation--next:hover:before,
.filter_datepicker .react-datepicker__navigation--previous:hover:before {
  opacity: 1;
}

.filter_datepicker .react-datepicker__header {
  background: transparent;
  padding-top: 0;
  border-color: transparent;
}

.filter_datepicker .react-datepicker__current-month {
  background: #555;
  color: #bbb;
  padding: 10px 0;
  font-weight: 300;
}

.filter_datepicker .react-datepicker__day-name, 
.filter_datepicker .react-datepicker__time-name {
  color: #bbb;
}