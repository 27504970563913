body._open-modal {
    overflow: hidden;
    height: 100vh;
  }
  .modal {
    position: fixed;
    top: 150%;
    bottom: -150%;
    left: 0;
    right: 0;
    z-index: 2500;
    height: 100vh;
    overflow: hidden;
    transition: all 0.5s ease-in-out 0s;
  }
  .modal__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100%;
    z-index: 10;
    transition: all .5s;
    overflow: hidden;
  }
  .modal__wrap {
    position: relative;
    z-index: 20;
    overflow: auto;
  }
  .modal__close {
    display: none;
  }
  .modal._open {
    top: 0;
    bottom: 0;
    overflow: auto;
    transition: all .5s ease-in-out .5s;
  }
  .modal._open .modal__close {
    display: block;
  }
  .modal._open .modal__bg {
    bottom: 0;
  }
  .modal._navbar .modal__bg {
    background: #00A88E url('img/bg-2.png') no-repeat center center;
    background-size: cover;
  }
  .modal._navbar .modal__close {
    position: fixed;
    width: 18px;
    height: 24px;
    left: 50%;
    top: 31px;
    margin-left: 570px;
  }
  .modal._navbar .modal__close:before,
  .modal._navbar .modal__close:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 20px;
    background: #fff;
  }
  .modal._navbar .modal__close:before {
    left: 0;
    top: 0;
    transform-origin: right top;
    transform: rotate(-45deg);
  }
  .modal._navbar .modal__close:after {
    right: 0;
    top: 0;
    transform-origin: left top;
    transform: rotate(45deg);
  }
  .modal._navbar .modal__wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
    padding: 60px 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .modal._navbar .modal__wrap ul {
    width: 100%;
    list-style-type: none;
    margin-bottom: 16px;
  }
  .modal._navbar .modal__wrap ul li {
    margin: 33px 0;
    line-height: 40px;
  }
  .modal._navbar .modal__wrap ul a {
    font-weight: 300;
    font-size: 36px;
    color: #FFFFFF;
    text-decoration: none;
  }
  .modal._user .modal__bg {
    background: rgba(255, 255, 255, 0.86);
  }
  .modal._user .modal__close {
    position: absolute;
    width: 26px;
    height: 28px;
    right: -43px;
    top: 0;
  }
  .modal._user .modal__close:before,
  .modal._user .modal__close:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 34px;
    background: rgba(0, 0, 0, 0.6);
  }
  .modal._user .modal__close:before {
    left: 0;
    top: 0;
    transform-origin: right top;
    transform: rotate(-45deg);
  }
  .modal._user .modal__close:after {
    right: 0;
    top: 0;
    transform-origin: left top;
    transform: rotate(45deg);
  }
  .modal._user {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
    padding: 60px 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .modal._user .modal__wrap {
    padding: 43px 22px 20px;
    width: 579px;
    background: rgba(242, 242, 242, 0.8);
    position: relative;
    overflow: visible;
  }
  .modal._user .modal__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 13px;
  }
  .modal._user .modal__photo {
    width: 126px;
    min-width: 126px;
    height: 126px;
    overflow: hidden;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .modal._user .modal__photo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .modal._user .modal__common {
    padding-left: 15px;
    padding-bottom: 10px;
  }
  .modal._user .modal__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #1D1D1B;
    margin-bottom: 10px;
  }
  .modal._user .modal__info {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #666666;
  }
  .modal._user .modal__info span {
    color: #1D1D1B;
  }
  .modal._user .modal__content {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #1D1D1B;
  }
  .modal._user .modal__content p {
    margin-bottom: 8px;
  }
  .modal._user .modal__content > *:last-of-type {
    margin-bottom: 0;
  }
  .modal._order {
    z-index: 3100;
  }
  .modal._order .modal__bg {
    background: #FAF39D url('img/bg-3.png') no-repeat center center;
    background-size: cover;
  }
  .modal._order .modal__bg:before {
    content: '';
    display: block;
    width: 883px;
    height: 150vh;
    background: #ffe810;
    position: absolute;
    left: 50%;
    top: -25vh;
    margin-left: -420px;
    transform-origin: center center;
    transform: rotate(12.5deg);
  }
  .modal._order .modal__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .modal._order .modal__header .container {
    position: relative;
    max-width: 891px;
    left: 11vh;
  }
  .modal._order .modal__lang {
    position: absolute;
    left: 20px;
    top: 7px;
  }
  .modal._order .modal__lang .button {
    font-size: 14px;
    line-height: 18px;
    color: #1D1D1B;
    min-width: 68px;
    padding: 0;
  }
  .modal._order .modal__close {
    position: absolute;
    width: 18px;
    height: 24px;
    top: 31px;
    left: 50%;
    margin-left: 570px;
  }
  .modal._order .modal__close:before,
  .modal._order .modal__close:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 20px;
    background: #444444;
  }
  .modal._order .modal__close:before {
    left: 0;
    top: 0;
    transform-origin: right top;
    transform: rotate(-45deg);
  }
  .modal._order .modal__close:after {
    right: 0;
    top: 0;
    transform-origin: left top;
    transform: rotate(45deg);
  }
  .modal._order .modal__wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
    padding: 60px 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .modal._order .modal__form .container {
    max-width: 851px;
    padding: 0 0px 0 55px;
  }