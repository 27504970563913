.entry {
    background: #20CBAD url('img/bg-1.png') no-repeat center center;
    background-size: cover;
    overflow: hidden;
  }
  .entry .container {
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 10px 20px 87px;
  }
  .entry__next-screen {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -12px;
    bottom: 20px;
    z-index: 100;
  }
  .entry__next-screen svg {
    fill: #fff;
  }
  .entry__title {
    font-weight: bold;
    font-size: 64px;
    line-height: 68px;
    letter-spacing: -0.01em;
    color: #1D1D1B;
    margin-bottom: 18px;
  }
  .entry__title:after {
    content: '';
    display: block;
    width: 36px;
    height: 2px;
    background: #000;
    margin-top: 8px;
  }
  .entry__sub-title {
    font-size: 16px;
    line-height: 20px;
    color: #1D1D1B;
    margin-bottom: 50px;
    max-width: 360px;
  }
  .entry__common {
    width: 50%;
    padding-top: 23px;
  }
  .entry__offset {
    width: 50%;
    position: relative;
    padding-top: 90px;
  }
  .entry__offset-bg {
    z-index: 5;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 793px;
    height: 842px;
    margin-left: -374px;
    margin-top: -356px;
    background: url('img/bg-1.svg') no-repeat center center;
  }
  .entry__offset .offset {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    z-index: 10;
  }
  .entry__offset .offset__photo {
    min-width: 211px;
    width: 211px;
    height: 211px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 10px;
  }
  .entry__offset .offset__photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .entry__offset .offset__common {
    padding-left: 22px;
    padding-right: 35px;
  }
  .entry__offset .offset__title {
    font-size: 36px;
    line-height: 52px;
    font-weight: bold;
    color: #1D1D1B;
    margin-bottom: 15px;
  }
  .entry__offset .offset__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #1D1D1B;
  }
  .entry__numbers {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 415px;
    margin-bottom: 56px;
  }
  .entry__numbers .item {
    max-width: 160px;
  }
  .entry__numbers .item__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #1D1D1B;
    margin-bottom: 14px;
  }
  .entry__numbers .item__title:after {
    content: '';
    display: block;
    width: 42px;
    height: 2px;
    background: #000;
    margin-top: 24px;
  }
  .entry__numbers .item__caption {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #1D1D1B;
  }
  .entry__button {
    margin-bottom: 49px;
  }
  .entry__button .button {
    width: 225px;
  }

  .entry__peoples {
    display: flex;
  }

.entry__peoples-icon {
  max-width: 140px;
}
.entry__peoples-common {
  padding-left: 23px;
  max-width: 360px;
}
.entry__peoples-title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #1D1D1B;
  margin-top: -7px;
  margin-bottom: 15px;
}
.entry__peoples-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #1D1D1B;
  margin-bottom: 7px;
}
.entry__peoples-link {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #1D1D1B;
  text-decoration: none;
  border-bottom: 1px dotted #000;
}