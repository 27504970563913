.user__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.user__photo {
  width: 170px;
  min-width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.user__photo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user__common {
  padding-left: 32px;
}
.user__count {
  font-size: 14px;
  line-height: 30px;
  color: #555;
}
.user__count span {
  font-size: 30px;
  margin-right: 5px;
  font-weight: bold;
  color: #00A88E;
}
.user__name {
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #00A88E;
  margin-bottom: 13px;
}
.user__info {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #1D1D1B;
  margin-bottom: 35px;
}
.user__comments .item._comments {
  margin-bottom: 35px;
}
.user__comments .item._comments .item__date {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 7px;
}
.user__comments .item._comments .item__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #1D1D1B;
}