  .item._author .item__wrap {
    display: block;
    background: rgba(242, 242, 242, 0.8);
    padding: 21px 6px 11px 6px;
    text-decoration: none;
    position: relative;
    transition: all .3s;
  }
  .item._author .item__wrap > * {
    position: relative;
    z-index: 2;
  }
  .item._author .item__wrap:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background: #efefef;
    z-index: 1;
    transition: all .3s;
  }
  .item._author .item__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 3px;
  }
  .item._author .item__photo {
    width: 60px;
    min-width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .item._author .item__photo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item._author .item__common {
    display: block;
    padding-left: 12px;
  }
  .item._author .item__count {
    position: absolute;
    top: -33px;
    right: 10px;
    font-weight: bold;
    font-size: 21px;
    line-height: 24px;
    color: #1D1D1B;
  }
  .item._author .item__count img {
    margin-left: 6px;
  }
  .item._author .item__title {
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #1D1D1B;
    margin-bottom: 7px;
  }
  .item._author .item__info {
    display: block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #666666;
    height: 38px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .item._author .item__info span {
    width: 100%;
    color: #1D1D1B;
  }
  .item._author .item__text {
    display: block;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #1D1D1B;
    padding: 0 4px;
  }
  .item._author .item__wrap:hover:before {
    right: 0;
    left: 0;
  }

  .item._date .item__wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 120px;
    height: 104px;
    position: relative;
    background: #BDE0F5;
    transition: all .3s;
  }
  .item._date .item__day {
    font-size: 36px;
    line-height: 38px;
    letter-spacing: -0.03em;
    color: #1D1D1B;
    font-weight: bold;
    transition: all .3s;
  }
  .item._date .item__month,
  .item._date .item__year {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #1D1D1B;
    transition: all .3s;
  }
  .item._date .item__remove {
    position: absolute;
    right: 5px;
    top: 3px;
    color: red;
    font-size: 14px;
    text-decoration: none;
  }

  .item._category .item__wrap {
    background: #BDE0F5;
    height: 216px;
    display: block;
    text-decoration: none;
    position: relative;
    overflow: hidden;
  }
  .item._category .item__img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  .item._category .item__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    transition: all .3s;
  }
  .item._category .item__title {
    font-weight: bold;
    display: block;
    font-size: 21px;
    line-height: 24px;
    color: #1D1D1B;
    padding-right: 20px;
    z-index: 1;
    position: relative;
    padding: 32px 0 0 34px;
    max-width: 170px;
  }
  .item._category .item__wrap:hover .item__img img {
    transform: scale(1.1);
  }


  .item._people {
    padding: 20px 20px 14px 20px;
    position: relative;
    margin-bottom: 21px;
  }
  .item._people .item__loader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(270deg, rgba(35, 209, 174, 0.5) 0%, rgba(255, 232, 16, 0.5) 51.04%, rgba(255, 55, 72, 0.5) 100%);
    z-index: 5;
    transition: all .3s;
  }
  .item._people .item__loader span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: #F8F8F8;
    transition: all .3s;
  }
  .item._people._active .item__loader span {
    background: #efefef;
  }
  .item._people .item__wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    z-index: 10;
    text-decoration: none;
  }
  .item._people .item__author {
    display: -ms-flexbox;
    display: flex;
  }
  .item._people .item__author-photo {
    width: 60px;
    min-width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .item._people .item__author-photo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item._people .item__author-common {
    padding-left: 10px;
    display: block;
  }
  .item._people .item__author-name {
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #1D1D1B;
    margin-bottom: 7px;
  }
  .item._people .item__author-info {
    display: block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #666666;
    height: 38px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .item._people .item__author-info span {
    width: 100%;
    color: #1D1D1B;
  }
  .item._people .item__count {
    width: 105px;
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    color: #00A88E;
    font-weight: bold;
  }
  .item._people .item__count span {
    display: block;
    font-size: 64px;
    line-height: 42px;
  }
  .item._people._current .item__loader span {
      
  }