.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2500;
}
.navbar .container {
    position: relative;
}
.navbar__trigger {
    position: absolute;
    right: 50px;
    top: 28px;
    width: 20px;
    display: block;
    z-index: 2600;
}
.navbar__trigger span {
    display: block;
    background: #00A88E;
    width: 100%;
    height: 3px;
    margin: 3px 0;
}
.navbar__trigger._black span {
    background: #1D1D1B;
}