.form._order .form__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #00A88E;
    text-align: center;
    margin-bottom: 45px;
  }
  .form._order .form__wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form._order .form__group {
    width: 48%;
    padding-right: 32px;
  }
  .form._order .form__group-title {
    font-size: 16px;
    line-height: 20px;
    color: #1D1D1B;
    margin-bottom: 23px;
    margin-left: 5px;
  }
  .form._order .form__group-title .bull {
    width: 11px;
    height: 11px;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background: #00A88E;
    vertical-align: middle;
    margin-left: 5px;
  }
  .form._order .form__group-title .bull:before {
    content: '';
    width: 275px;
    height: 1px;
    background: #00A88E;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1px;
  }
  .form._order .form__field {
    margin-bottom: 17px;
  }
  .form._order .form__content {
    width: 52%;
    position: relative;
    padding: 0 8px;
    z-index: 20;
    background: #ffe810;
  }
  .form._order .form__content:before {
    content: '';
    width: 24px;
    position: absolute;
    left: 0;
    top: -16px;
    bottom: -16px;
    border-left: 1px solid #00A88E;
    border-top: 1px solid #00A88E;
    border-bottom: 1px solid #00A88E;
  }
  .form._order .form__content:after {
    content: '';
    width: 24px;
    position: absolute;
    right: 0;
    top: -16px;
    bottom: -16px;
    border-right: 1px solid #00A88E;
    border-top: 1px solid #00A88E;
    border-bottom: 1px solid #00A88E;
  }
  .form._order .form__content p {
    margin-bottom: 14px;
  }
  .form._order .form__content ul {
    list-style-type: none;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #1D1D1B;
  }
  .form._order .form__content ul li {
    margin-bottom: 5px;
  }
  .form._order .form__content ul li:last-of-type {
    margin-bottom: 0;
  }
  .form._order .form__content b {
    font-size: 16px;
    line-height: 20px;
    color: #00A88E;
  }
  .form._order .form__footer {
    width: 100%;
    text-align: center;
    margin-top: 59px;
    padding-right: 55px;
  }
  .form._order .form__footer .button {
    margin: 0 20px;
  }